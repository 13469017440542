/* global Component,__lodash__,axios */
window.centerCostTemplate = Object.freeze({
    "__meta__": {
        "__classname__": "CenterCost",
        "__error_responses__": [],
        "__ismodified__": true,
        "__isnew__": true
    },
    "fields": {
        "Closed": null,
        "Code": null,
        "Comment": null,
        "CustCode": null,
        "CustName": null,
        "DelAddress": null,
        "DelAddressCode": null,
        "FromDay": null,
        "LimitMaxSalesAmounts": null,
        "MaxMonthlySalesAmount": null,
        "MaxSalesAmount": null,
        "MinSalesAmount": null,
        "Name": null,
        "QtyAllowedMonthly": null,
        "RecordSource": null,
        "ToDay": null,
        "createTime": null,
        "createUser": null,
        "internalId": null,
        "syncVersion": null,
        "updateTime": null,
        "updateUser": null,
        "Contacts": [{
            "__meta__": {
                "__classname__": "CenterCostContactRow",
                "__error_responses__": [],
                "__ismodified__": true,
                "__isnew__": true
            },
            "fields": {
                "IsMainContact": null,
                "PersonCode": null,
                "PersonName": null,
                "RowViewNumber": null,
                "internalId": null,
                "masterId": null,
                "rowId": null,
                "rowNr": 0
            },
            "oldFields": {
                "IsMainContact": null,
                "PersonCode": null,
                "PersonName": null,
                "RowViewNumber": null,
                "internalId": null,
                "masterId": null,
                "rowId": null,
                "rowNr": null
            },
            "removedRows": {}
        }
        ],
        "Liberators": [
            {
                "__meta__": {
                    "__classname__": "CenterCostLiberatorRow",
                    "__error_responses__": [],
                    "__ismodified__": true,
                    "__isnew__": true
                },
                "fields": {
                    "CanLiberate": true,
                    "OnlyModified": false,
                    "PersonCode": null,
                    "PersonName": null,
                    "RowViewNumber": null,
                    "internalId": null,
                    "masterId": null,
                    "rowId": null,
                    "rowNr": 0
                },
                "oldFields": {
                    "CanLiberate": null,
                    "OnlyModified": null,
                    "PersonCode": null,
                    "PersonName": null,
                    "RowViewNumber": null,
                    "internalId": null,
                    "masterId": null,
                    "rowId": null,
                    "rowNr": null
                },
                "removedRows": {}
            }
        ],
    },
    "oldFields": {
        "Closed": null,
        "Code": null,
        "Comment": null,
        "CustCode": null,
        "CustName": null,
        "DelAddress": null,
        "DelAddressCode": null,
        "FromDay": null,
        "LimitMaxSalesAmounts": null,
        "MaxMonthlySalesAmount": null,
        "MaxSalesAmount": null,
        "MinSalesAmount": null,
        "Name": null,
        "QtyAllowedMonthly": null,
        "RecordSource": null,
        "ToDay": null,
        "createTime": null,
        "createUser": null,
        "internalId": null,
        "syncVersion": null,
        "updateTime": null,
        "updateUser": null
    },
    "removedRows": {
        "Contacts": [],
        "Liberators": []
    }
});
class centerCostManagerComponent extends Component {

    static name() {
        return "centerCostManagerComponent";
    }

    static componentName() {
        return "centerCostManagerComponent";
    }

    getData() {
        return function (){
            return {
                isloading: false,
                recordList: [],
                currentRecord: null,
                columns: ['Code', 'Name', 'CustCode', 'CustName', "Actions"],
                css: {
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                    table: {
                        loadingClass: 'loading',
                        ascendingIcon: 'blue chevron up icon',
                        descendingIcon: 'blue chevron down icon',
                    }
                },
                options: {
                    preserveState: true,
                    filterByColumn: false,
                    filterable: ["Code", "Name", "CustName"],
                    headings: {
                        'Code': this.tr('Code'),
                        'Name': this.tr('Name'),
                        'CustCode': this.tr('Customer Code'),
                        'CustName': this.tr('CustName'),
                        'Actions': this.tr('Edit')
                    },
                    perPage: 10,
                    pagination: {
                        chunk: 15,
                    },
                    templates: {
                        'Actions': 'centerCostActionsComponent',
                    },
                    texts: {
                        count: `Showing {from} to {to} of {count} ${this.tr('Cost Center')}|{count} ${this.tr('Cost Center')}|One ${this.tr('Cost Center')}`,
                        filter: '',
                        limit: '',
                        filterPlaceholder: '',
                        noResults: this.tr('No Cost Center founds'),
                        page: this.tr('Page') + ":", // for dropdown pagination
                        filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                        loading: this.tr('Loading') + '...', // First request to server
                        defaultOption: 'Select {column}', // default option for list filters,
                    },
                    dateFormat: "DD-MM-YYYY",
                    uniqueKey: "Code"
                }
            };
        };
    }



    mounted(){
        return async function (){
            this.loadRecords();
            this.editCenterCost = this.editCenterCost.bind(this);
            this.deleteCenterCost = this.deleteCenterCost.bind(this);
            this.reloadCenterCost = this.reloadCenterCost.bind(this);
            this.subscribeEvent('addCenterCost',this.reloadCenterCost);
            this.subscribeEvent('editCenterCost',this.editCenterCost);
            //this.subscribeEvent('editCenterCost',this.reloadCenterCost);
            this.subscribeEvent('deleteCenterCost',this.deleteCenterCost);
        };
    }

    getMethods() {
        return {
            reloadCenterCost:this.reloadCenterCost,
            editCenterCost:this.editCenterCost,
            deleteCenterCost: this.deleteCenterCost,
            loadRecords:this.loadRecords,
            openCenterCostModal: this.openCenterCostModal
        };
    }

    reloadCenterCost(cc){
        this.loadRecords();
    }

    editCenterCost(cc){
        this.currentRecord = cc;
    }

    async deleteCenterCost(cc){
        //console.log("DELETING",cc);
        this.$store.dispatch('toogleLockScreen','Deleting Center Costs');
        let responseDeleting = await axios.post("/ecommerce/api/saveCenterCost", {action:"delete",code:cc.Code});
        if(responseDeleting.data.success) {
            this.$store.dispatch('showNotificacion', {
                title: `Centro de Costo`,
                message: `Eliminado Centro de Costo ${cc.Code}`,
                type: 'info'
            });
            this.loadRecords();
        }
        this.$store.dispatch('toogleLockScreen',null);
    }

    async loadRecords() {
        this.isloading = true;
        this.$store.dispatch('toogleLockScreen','Loading Center Costs');
        let response = await axios.get('/ecommerce/api/getCenterCost',{params:{byCustomer:1}});
        if (response.data.centerCost) {
            this.recordList = response.data.centerCost;
        }
        this.isloading = false;
        this.$store.dispatch('toogleLockScreen',null);
    }

    async openCenterCostModal() {
        this.$store.dispatch('clearSelectsLinkto');
        let currentRecord = __lodash__.cloneDeep(window.centerCostTemplate);
        currentRecord.fields.Contacts = [];
        currentRecord.fields.Liberators = [];
        currentRecord.fields.CustCode = this.$store.state.customer.Code;
        currentRecord.fields.CustName = this.$store.state.customer.Name;
        this.currentRecord = currentRecord;
        this.emitEvent('openModalCenterCost');
    }


    getTemplate(){
        return `<div  class="row" id="AdminCenterCost" >
                <centerCostModalComponent :paramCenterCostRecord="currentRecord"> </centerCostModalComponent>
                <div class="view-mode float-sm-right mb-3 ml-1 mr-2 vue-table-additional-buttons">
                    <button type="submit" class="btn btn-custom" @click="openCenterCostModal()"><i class="fas fa-user-plus fa-fw"></i> {{tr('Add Cost Center')}}</button>
                </div>
                <div class="container center section white" v-if="isloading">
                    <div class="row justify-content-center align-items-center profile-loading">
                       <div class="col-8 text-center">
                           <img class="img-fluid" src="../static/ecommerce/img/lg-spinner-gif.gif" >
                           <p class="font-weight-bold">{{tr('Loading Report Data')}}</p>
                       </div>
                    </div>
                </div>
                <v-client-table class="custom-vue-table" @rowclick="" :columns="columns" :data="recordList" :options="options" :css="css" v-else>
                    <div slot="afterFilter" class="VueTables__search-field">
                       <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2"><i class="icon fas fa-search"></i></span>
                      </div>
                    </div>
                </v-client-table>
            </div>`;
    }

}

centerCostManagerComponent.registerComponent();